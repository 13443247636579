<template>
  <nav>
    <ul class="menu">
      <li>
        <div class="div-logo">
          <img src="@/assets/imagens/logo/Ellipse1.png" class="logo">
          <img src="@/assets/imagens/logo/Frame.png" class="nome">
        </div>
      </li>
      <li><button>HOME</button></li>
      <li><button>CONHEÇA O SISTEMA</button></li>
      <li><button>SOBRE A EMPRESA</button></li>
      <li>
        <button class="botao" @click.prevent="click()">
          Acessar
        </button>
      </li>
    </ul>
    <div class="pop-up-tenant" v-if="clicado">
      <div class="flex justify-center">
        <input type="text" class="max-w-px">
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Menu",

  data() {
    return {
      url: window.location.protocol + window.location.host,
      clicado: false
    }
  },

  methods: {
    click() {
      this.clicado = !this.clicado
    }
  },
}
</script>

<style scoped>

/*Menu*/

.menu{
  @apply flex justify-between mt-6 px-6
}

.menu>li{
  @apply text-white my-auto
}

/*Logo*/

.div-logo{
  @apply flex
}

.logo{
  @apply h-8 mr-4
}

.nome{
  @apply h-4 my-auto
}


/*Botão de login*/
.botao{
  @apply px-4 py-1 text-white font-medium border border-white
}

.pop-up-tenant {
  @apply relative bg-[#4b9fb2] left-[86vw] p-8 w-40 rounded-lg mt-3
}


</style>