<template>
  <div>

    <!--Cabeçalho-->
    <header>
      <Menu class="menu-cabecalho"></Menu>
      <carrossel></carrossel>
    </header >

    <!--Conteudo-->
    <conteudo></conteudo>

    <!--Gráfico-->
    <grafico></grafico>

    <!--Vantagens do sistema-->
    <vantagens-sistema></vantagens-sistema>

    <!--Rodape-->
    <rodape></rodape>
  </div>
</template>

<script>

import Menu from "@/components/layouts/Menu";
import Carrossel from "@/components/layouts/Carrossel";
import Conteudo from "@/components/layouts/Conteudo";
import VantagensSistema from "@/components/layouts/VantagensSistema";
import Rodape from "@/components/layouts/Rodape";

export default {
  name: 'HomeView',
  components: {
    Menu,
    Carrossel,
    Conteudo,
    VantagensSistema,
    Rodape
  }
}
</script>

<style scoped>

/*Cabeçalho*/
header{
  @apply relative
}

.menu-cabecalho{
  @apply absolute z-10 w-full
}
</style>
