<template>
  <section>
    <h2>Vantagens do ManagerGlass</h2>
    <ul class="lista-vantegem">

      <li class="item-lista-vantagem">
        <img class="icone" src="@/assets/imagens/icones/produtividade.png">
        <p class="titulo">Processo Fabril</p>
        <p class="descricao">Tenha gestão eficiente de todo processo fabril</p>
      </li>

      <li class="item-lista-vantagem">
        <img class="icone" src="@/assets/imagens/icones/projeto.png">
        <p class="titulo">Projeto</p>
        <p class="descricao">Inspire com ousadia com o sistema de projetos da managerglass</p>
      </li>

      <li class="item-lista-vantagem">
        <img class="icone" src="@/assets/imagens/icones/estoque.png">
        <p class="titulo">Vendas</p>
        <p class="descricao">Estimule os vendedores com um sistema de vendas eficiente</p>
      </li>

      <li class="item-lista-vantagem">
        <img class="icone" src="@/assets/imagens/icones/ok.png">
        <p class="titulo">Controle</p>
        <p class="descricao">Eleve o controle ao máximo de eficiência e eficácia</p>
      </li>

      <li class="item-lista-vantagem">
        <img class="icone" src="@/assets/imagens/icones/financeiro.png">
        <p class="titulo">Financeiro</p>
        <p class="descricao">Seu controle financeiro nunca mais será o mesmo</p>
      </li>

      <li class="item-lista-vantagem">
        <img class="icone" src="@/assets/imagens/icones/atualizacao.png">
        <p class="titulo">Gestão</p>
        <p class="descricao">Conduza a gestão de onde você estiver</p>
      </li>

    </ul>
  </section>
</template>

<script>
export default {
  name: "VantagensSistema"
}
</script>

<style scoped>

h2{
  @apply mt-4 mb-16 text-center text-xl font-bold text-[#164E63]
}

.lista-vantegem{
  @apply w-full flex flex-wrap px-6
}

.item-lista-vantagem{
  @apply mb-16 flex w-1/3 flex-col items-center
}

.item-lista-vantagem>.icone{
  @apply w-24
}

.item-lista-vantagem>.descricao{
  @apply font-medium text-[#164E63] w-80 text-center
}

.titulo {
  @apply text-[#164E63] text-2xl mb-2 font-bold
}

</style>