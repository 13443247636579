<template>
  <div class="carrossel">
    <carrossel-image :slides="slides" :descricao="descricao" :class="carrossel-image"></carrossel-image>
  </div>
</template>

<script>
import CarrosselImage from "@/components/layouts/carrossel/CarrosselImage.vue"

export default {
  name: "Carrossel",
  components:{
    CarrosselImage,
  },

  data(){
    return{
      slides:["casa1","casa2","casa3","casa4"],
      descricao:["Maximize sua produtividade com nosso sistema.",
        "Melhoria no gerenciamento de projetos.",
        "Controle preciso do estoque",
        "Controle financeiro aprimorado"]
    }
  },
}
</script>

<style scoped>

.carrossel{
  @apply h-screen overflow-hidden relative
}
</style>