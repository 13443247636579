<template>
  <footer  class="rodape">
    <div>
      <img class="logo" src="@/assets/imagens/logo/TextoLogoBranco.png">
      <ul class="redes-sociais">
        <li><img src="@/assets/imagens/icones/facebook.png"></li>
        <li><img src="@/assets/imagens/icones/instagram.png"></li>
        <li><img src="@/assets/imagens/icones/linkedin.png"></li>
      </ul>
    </div>

    <p class="copyright">© {{ lastedYear }} • ManagerGlass</p>
  </footer>
</template>

<script>
export default {
  name: "Rodape",

  data() {
    return {
      lastedYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

/*Rodape*/
.rodape{
  @apply flex justify-around items-center bg-[#164E63] py-8
}

/*Lado direito*/
.rodape>div{
  @apply flex items-center
}

.logo{
  @apply w-36
}

.redes-sociais{
  @apply flex my-4
}

.redes-sociais>li{
  @apply bg-white w-8 p-2 rounded-full mx-4
}

/*Lado esquerdo*/

.copyright{
  @apply text-white text-lg font-light
}

</style>