<template>
 <div>

   <article class="conteudo">

     <div class="texto-conteudo">
       <h2 class="titulo-conteudo">Sobre a empresa</h2>
       <p class="descricao-conteudo">
         Nosso objetivo é fornecer um software de gestão especializado que aumente a produtividade, otimize o gerenciamento de projetos e impulsione o crescimento dos nossos clientes. Queremos oferecer soluções personalizadas e suporte técnico excepcional para ajudar as empresas a melhorar sua eficiência operacional, maximizar a lucratividade e fortalecer sua posição competitiva.
       </p>
     </div>

     <div class="div-foto-conteudo"><img src="@/assets/imagens/fotos/casa2sf.jpg"></div>
   </article>

   <article>
    <div class="conteudo tipo-2">
      <div class="texto-conteudo bg-opacity-60 bg-black">
        <h2 class="titulo-conteudo">Valores da empresa</h2>
        <p class="descricao-conteudo">
        Excelência. Inovação. Confiança. Parceria. Orientação ao cliente. Responsabilidade social e ambiental. Aprendizado contínuo. Foco no resultado.
        </p>
      </div>
    </div>
   </article>

 </div>
</template>

<script>

export default {
  name: "Conteudo"
}
</script>

<style scoped>

/*Articles*/

.conteudo{
  @apply flex p-6
  md:flex-row
}

.tipo-2{
  background-image:url("@/assets/imagens/fotos/casa2.jpg");
  @apply flex flex-row justify-end bg-center bg-cover bg-fixed
}

/*Div texto do conteudo*/

.texto-conteudo{
  @apply w-auto h-auto px-6 py-10 flex flex-col justify-center 
  md:flex md:w-1/2 md:h-96
}

/*Texto do conteudo*/

.titulo-conteudo{
  @apply font-bold mb-6 text-[#164E63] text-lg
  md:mb-10
  lg:text-3xl
}

.descricao-conteudo{
  @apply font-medium text-[#1E1E1E] text-sm
  lg:text-lg
}

.tipo-2>.texto-conteudo>.titulo-conteudo{
  @apply text-white
}

.tipo-2>.texto-conteudo>.descricao-conteudo{
  @apply text-white
}

/*Foto do conteudo*/

.div-foto-conteudo{
  @apply w-full shadow-2xl h-64
  sm:h-72
  md:w-1/2
}
.div-foto-conteudo>img{
  @apply object-cover w-full h-64
  sm:h-72
  md:h-96
}
</style>