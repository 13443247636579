<template>
  <div class="carrossel">
      <p class="descricao-carrossel">{{descricao[fotoExibida]}}</p>
        <ul class="numero-foto">
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 0"><button type="button" @click="reiniciarTempoIntervalo(0)">01</button></li>
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 1"><button type="button" @click="reiniciarTempoIntervalo(1)">02</button></li>
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 2"><button type="button" @click="reiniciarTempoIntervalo(2)">03</button></li>
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 3"><button type="button" @click="reiniciarTempoIntervalo(3)">04</button></li>
        </ul>
      <item-carrossel v-for="(slide, index) in slides" :slide="slide" :key="`item-${index}`" :foto-exibida="fotoExibida" :index="index"/>
  </div>
</template>

<script>
import ItemCarrossel from './ItemCarrossel.vue'
export default {
    name:"Carrossel",
    components: { ItemCarrossel },
    data:() =>({
        fotoExibida:0,
        intervalo:null,
    }),
    props:["slides","selecionarFoto","descricao"],

    methods:{
        definirFotoExibida(index){
            this.fotoExibida = index;
        },
        tempoIntervalo(){
          const index = this.fotoExibida < this.slides.length -1 ? this.fotoExibida + 1 : 0;
          this.definirFotoExibida(index);
        },
        reiniciarTempoIntervalo(num){
          clearInterval(this.intervalo)
          this.fotoExibida = num
          this.intervalo = setInterval(this.tempoIntervalo,5000)
        }
    },
    mounted(){

        this.intervalo = setInterval(this.tempoIntervalo,5000)
    },

    beforeUnmount(){
        clearInterval(this.intervalo)
    }

}
</script>

<style>
.descricao-carrossel{
  @apply absolute z-10 w-auto text-white text-lg font-bold px-6 bottom-12
  lg:text-3xl
}

.numero-foto{
  @apply flex flex-col items-end absolute z-10 right-6 top-[calc((100%-192px)/2)]
}

.numero-foto>li{
  @apply flex text-white font-bold text-lg my-4
}

.indicador{
  @apply w-4 mr-2
}

</style>