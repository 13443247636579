<template>
    <transition name="fotos">
      <div class="carrossel-item" v-show="fotoExibida === index">
        <img :src="require(`@/assets/imagens/fotos/${slide}.jpg`)">
      </div>
    </transition>
</template>

<script>
export default {
    name:"ItemCarrossel",
    props:["slide", "fotoExibida", "index"],    
}
</script>

<style scoped>
.carrossel-item{
    @apply absolute bg-black
}

img{
  @apply w-screen h-screen object-cover opacity-40
}

.fotos-enter-active, .fotos-leave-active{
    @apply transition-all duration-700 ease-in-out
}

.fotos-enter-from{
    transform: translateY(-100%);
}

.fotos-leave-to{
    transform: translateY(100%);
}

</style>